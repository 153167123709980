import React,{useEffect} from 'react'
import about from "../images/about.jpg"
import './About.css'
import Aos from 'aos'
import 'aos/dist/aos.css'

const About = () => {
    useEffect(() =>{
        Aos.init({duration:2000});
    }, [])
    return (
        <div id='about' className="about-container">
        <img src={about} alt="landscaping" />
            <div data-aos="fade-left" className="about-content">
                <h2> About Us</h2>
                <p>Yorkshire Groundswork and Garage Conversion Specialists is an owner operated business that has been servicing the Yorkshire for 25 years. No job is too big or small, we service both residential and commercial properties with a high level of attention to detail. </p>
            </div>

        </div>
    )
}

export default About;
