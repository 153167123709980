import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Construction from "./pages/Construction";
import Landscaping from "./pages/Landscaping";
import Home from "./pages/Home";
import ArtificialGrass from "./pages/ArtificialGrass";
import NotFound from "./components/NotFound";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/Construction">
            <Construction />
          </Route>
          <Route path="/Landscaping">
            <Landscaping />
          </Route>
          <Route path="/ArticialGrass">
            <ArtificialGrass />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
