import React from 'react'
import Hero from '../components/Hero'
import Services from '../components/Services'
import Testimonial from '../components/Testimonial'
import {ServicesData} from '../components/data/servicesData'
import {homeImageData} from '../components/data/imageData'
import {testimonialData} from '../components/data/testimonialData'
import About from '../components/About'
import Gallery from '../components/Gallery'
import {homeData} from '../components/data/HeroData'
import Footer from "../components/Footer";


const Home = () => {

    
    return (
        <main>
            <Hero data={homeData} />
            <Services  data={ServicesData} />
            <About  />
            <Gallery heading="Our Finest Work" data={homeImageData} />
            <Testimonial heading="Testimonials" Testimonial={testimonialData} />
            <Footer />

        </main>
    )
}

export default Home;
