import React from "react";
import "../components/Footer.css";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Footer = () => {
  const today = new Date();

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9ry2qtb",
        "template_z2p6ult",
        e.target,
        "user_KGysl3qmSKFIzCF3yRdm3"
      )
      .then(
        (result) => {
          toast.success("Message Sent!", {
            className: "Toast-success",
            draggable: false,
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
          });
          console.log(result.text);
        },
        (error) => {
          toast.error("Something went wrong! please try again", {
            className: "Toast-Error",
            draggable: false,
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
          });
          console.log(error.text);
        }
      );

    e.target.reset();
  }
  return (
    <footer id="contact">
      <div className="main-content">
        <div className="left box">
          <h2>About us</h2>
          <div className="content">
            <p>
              With over 20 years experience, we always complete our work to a
              high standard. Please take a look at our work and get in touch for
              a quote.
            </p>
            <div className="social">
              <a href="https://www.facebook.com/YorkshireLandscapeBuilders">
                <span className="fab fa-facebook-f"></span>
              </a>
            </div>
          </div>
        </div>

        <div className="center box">
          <h2>Address</h2>
          <div className="content">
            <div className="place">
              <span className="fas fa-map-marker-alt"></span>
              <span className="text">
                31 Bromley Mount Wakefield West Yorkshire
              </span>
            </div>
            <a  href="https://api.WhatsApp.com/send?phone=447762313727"className="phone">
              <span className="fas fa-phone-alt"></span>
              <span className="text">
                {" "}
                <a href="https://api.WhatsApp.com/send?phone=447762313727">
                  {" "}
                  +447762313727{" "}
                </a>
              </span>
            </a>
            <a href="mailto:yorkshirelandscapebuilders@gmail.com"  className="email ">
              <span className="fas fa-envelope"></span>
              <span className="text">
                {" "}
                <a href="mailto:yorkshirelandscapebuilders@gmail.com" >
                  {" "}
                  yorkshirelandscapebuilders@gmail.com{" "}
                </a>
              </span>
            </a>
          </div>
        </div>

        <div className="right box">
          <h2>Contact us</h2>
          <div className="content">
            <form onSubmit={sendEmail}>
              <div className="name">
                <div className="text">Name *</div>
                <input type="name" required name="name" />
              </div>
              <div className="email">
                <div className="text">Email *</div>
                <input type="email" required name="email" />
              </div>
              <div className="msg">
                <div className="text">Message *</div>
                <textarea name="message" />
              </div>
              <div className="btn">
                <button type="submit" value="Send Message">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="bottom">
        <center>
          <span className="far fa-copyright"></span>
          <span> {today.getFullYear()} All rights reserved.</span>
        </center>
      </div>
    </footer>
  );
};

export default Footer;
