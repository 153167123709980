import React from 'react'
import { landscapingData } from '../components/data/HeroData'
import { LandscapingData } from '../components/data/inforData'
import Hero from '../components/Hero'
import Infor from '../components/Infor'
import Gallery from '../components/Gallery'
import {LandscapingImageData} from '../components/data/imageData'
import {testimonialData} from '../components/data/testimonialData'
import Testimonial from '../components/Testimonial'
import "./pages.css"
import Offer from '../components/Offer'
import About from '../components/About'
import Footer from "../components/Footer";

const Landscaping = () => {
    return (
        <main>
            <Hero data={landscapingData} />
            <Infor data={LandscapingData} />
            <Gallery heading="Our Finest Work" data={LandscapingImageData} />
            <About  />
            <Testimonial heading="Testimonials" Testimonial={testimonialData} />
            <Offer />
            <Footer />
        </main>
    )
}

export default Landscaping
