import React from "react";
import "./Hero.css";
import Header from "../components/Header";
import { Link } from "react-scroll";

const Hero = ({ data }) => {
  return (
    <div className="hero-contianer">
      <Header />
      {data.map((data, index) => {
        return (
          <div key={index} className="text-container">
            <h1>{data.h1}</h1>
            <p>{data.p}</p>
            <Link to={data.to}>
              <button  className="hero-btn">{data.button}</button>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Hero;
