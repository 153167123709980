import React from 'react'
import {Link} from "react-router-dom"
import './NotFound.css'
import NotFoundimage from "../images/404.svg"
const NotFound = () => {
    return (
        <div className='Not-found'>
            <img className="not-found-image" src={NotFoundimage} alt="Yorkshire Landscape Builders" />
            <h1 className='not-found-title'>Page Not Found</h1>
            <Link to="/">
                <button className="button">Home</button>
            </Link>
        </div>
    )
}

export default NotFound
