export const ConstructionData = [
    {
        p: 'We offer reliable construction services and timely completion of projects, and although the work is generally dirty, we will aim to be as tidy as possible.'
    },
    {
        p: 'From new patios, courtyards and driveways to turfing, decking, paths and brickworks, your new outdoor flooring will transform the space into an area to be proud of.'
    },
    {
        p: 'We have also worked on numerous projects for more contemporary gardens including water features, rockeries, fencing and pergolas.'
    },
    {
        p: 'We always use quality materials from reputable manufactures, including block paving, permeable paving, Tarmac, gravel, Indian Stone and York Stone.'
    },

]
export const LandscapingData = [
    {
        p: 'We offer reliable construction services and timely completion of projects, and although the work is generally dirty, we will aim to be as tidy as possible.'
    },
    {
        p: 'From new patios, courtyards and driveways to turfing, decking, paths and brickworks, your new outdoor flooring will transform the space into an area to be proud of.'
    },
    {
        p: 'We have also worked on numerous projects for more contemporary gardens including water features, rockeries, fencing and pergolas.'
    },
    {
        p: 'We always use quality materials from reputable manufactures, including block paving, permeable paving, Tarmac, gravel, Indian Stone and York Stone.'
    },

]
export const GrassData = [
    {
        p: 'We offer reliable construction services and timely completion of projects, and although the work is generally dirty, we will aim to be as tidy as possible.'
    },
    {
        p: 'From new patios, courtyards and driveways to turfing, decking, paths and brickworks, your new outdoor flooring will transform the space into an area to be proud of.'
    },
    {
        p: 'We have also worked on numerous projects for more contemporary gardens including water features, rockeries, fencing and pergolas.'
    },
    {
        p: 'We always use quality materials from reputable manufactures, including block paving, permeable paving, Tarmac, gravel, Indian Stone and York Stone.'
    },

]