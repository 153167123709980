export const homeData =[
    {
        h1: 'Creating a Beautiful Home',
        p: 'Yorkshire Groundswork and Garage Conversion Specialists',
        button: 'View Gallery',
        to: 'gallery'
    }
]
export const constructionData =[
    {
        h1: 'For all your Construction Services',
        p: 'we make sure your dream design comes to life',
        button: 'Get a Quote',
        to: 'contact'
    }
]

export const landscapingData = [
    {
        h1: 'Make you lawn stand out from the rest',
        p: 'With our landscaping services',
        button: 'Get a Quote',
        to: 'contact'
    }
]

export const grassData = [
    {
        h1: 'The grass will always be greener on your side',
        p: 'with our artificial grass services',
        button: 'Get a Quote',
        to: 'contact'
    }
]
