import React, { useEffect } from 'react'
import '../components/Infor.css'
import Aos from 'aos'
import 'aos/dist/aos.css'


const Infor = ({ data }) => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])

    return (
        <div className='infor-container'>
            <div data-aos="fade-right" className='infor-content'>
                <h1 className='infor-title'>We offer</h1>
                {data.map((data, index) => {
                    return (
                        <p key={index}>{data.p}</p>
                    )
                })}
            </div>

        </div>
    )
}

export default Infor
