import React, { useEffect } from 'react'
import Carousel from 'react-elastic-carousel'
import "../components/Testimonial.css"
import Aos from 'aos'
import 'aos/dist/aos.css'



const Testimonial = ({ heading, Testimonial }) => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])

    return (
        <div data-aos="fade-up" className='testimonial'>
            <h1>{heading}</h1>
            <Carousel  className="test-context" itemsToShow={1}
                itemsToScroll={1}
                itemPadding={[10, 30]}
            >
                {Testimonial.map((Testimonial) => {
                    return (
                        <div key={Testimonial.id}>
                            <h2>{Testimonial.testimony}</h2>
                            <p>{Testimonial.name}</p>
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )
}

export default Testimonial
