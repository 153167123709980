import React from 'react'
import { grassData } from '../components/data/HeroData';
import { GrassData } from '../components/data/inforData';
import Hero from '../components/Hero'
import Infor from '../components/Infor';
import Gallery from '../components/Gallery'
import {GrassImageData} from '../components/data/imageData'
import {testimonialData} from '../components/data/testimonialData'
import Testimonial from '../components/Testimonial'
import "./pages.css"
import Offer from '../components/Offer';
import About from '../components/About'
import Footer from "../components/Footer";


const ArtificialGrass = ({data}) => {
    return (
        <main>
            <Hero data={grassData} />
            <Infor data={GrassData} />
            <Gallery heading="Our Finest Work" data={GrassImageData} />
            <About  />
            <Testimonial heading="Testimonials" Testimonial={testimonialData} />
            <Offer />
            <Footer />
        </main>
    )
}

export default ArtificialGrass;
