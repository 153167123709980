import React, { useEffect } from 'react'
import '../components/Gallery.css'
import Gridlist from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import { makeStyles } from '@material-ui/core/styles';
import Aos from 'aos'
import 'aos/dist/aos.css'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        width: "100vw",
        height: "100%",
    },

    gridlist: {
    },
    img: {
        objectFit: "cover",
    }

}));

const Gallery = ({ heading, data }) => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])
    const classes = useStyles();
    return (
        <div id="gallery" className="gallery-container">
            <div className={classes.root}>
                <h2 className="title">{heading}</h2>
                <Gridlist data-aos="fade-up" cellHeight={200} className={classes.gridList} cols={3}>
                    {data.map((image, index) => {
                        return (
                            <GridListTile key={image.id} cols={image.col || 1}>
                                <img src={image.image} alt={image.alt} className={classes.img} />
                            </GridListTile>
                        )
                    })}
                </Gridlist>
            </div>
        </div>
    )
}

export default Gallery
