
import Construction from '../../images/Wall.svg'
import Landscaping from '../../images/Lawn.svg'
import Artficial from '../../images/grass.svg'


export const ServicesData = [
    {
        icon: Construction,
        alt: 'Construction',
        name: 'Construction',
        Desc:'Including paver walls, patios, walkways and decorative stone installation',
        to: '/Construction'
    },
    {
        icon: Landscaping,
        alt: 'Landscaping',
        name: 'Landscaping',
        Desc: 'We offer many landscaping services including design, planting, mulching and much more',
        to: '/Landscaping'
    },
    {
        icon: Artficial,
        alt: 'Artificial Grass',
        name: 'Artificial Grass',
        Desc: 'We provide mowing, weeding, hedge trimming and fall & spring cleanups',
        to: '/ArticialGrass'

    }
]