import React, {useEffect} from 'react'
import {Link } from 'react-router-dom'
import "../components/Services.css"
import Aos from 'aos'
import 'aos/dist/aos.css'

const Services = ({data}) => {

    useEffect(() =>{
        Aos.init({duration:2000});
    }, [])
    return (
        <div data-aos="fade-up" className="services-section">
            <div className="services-container">
                <div className="row">
                {data.map ( (Services, index) => {
                    return(
                    <Link to={Services.to} key={index} className="col">
                        <img  src={Services.icon} alt={Services.alt} />
                        <h2 className="services-title">{Services.name}</h2>
                        <p className="services-desc">{Services.Desc}</p>
                    </Link>
                    )
                })}
                </div>
            </div>
        </div>
    )
}

export default Services;
