export const testimonialData = [
    {
        id:1,
        testimony: 'Gary, Mick and the lad were proffesional friendly, and hardworking, would not hesitate to highly recommend them, Thank you the drive looks great',
        name: 'David'
    },
    {
        id:2,
        testimony: 'Gary, Mick and the lad were proffesional friendly, and hardworking, would not hesitate to highly recommend them, Thank you the drive looks great',
        name: 'David'
    },
    {
        id:3,
        testimony: 'Gary, Mick and the lad were proffesional friendly, and hardworking, would not hesitate to highly recommend them, Thank you the drive looks great',
        name: 'David'
    },
    {
        id:4,
        testimony: 'Gary, Mick and the lad were proffesional friendly, and hardworking, would not hesitate to highly recommend them, Thank you the drive looks great',
        name: 'David'
    },
    {
        id:5,
        testimony: 'Gary, Mick and the lad were proffesional friendly, and hardworking, would not hesitate to highly recommend them, Thank you the drive looks great',
        name: 'David'
    },
    {
        id:6,
        testimony: 'Gary, Mick and the lad were proffesional friendly, and hardworking, would not hesitate to highly recommend them, Thank you the drive looks great',
        name: 'David'
    },
]