import imageOne from '../../images/img-nine.jpg'
import imageTwo from '../../images/img-two.jpg'
import imageThree from '../../images/img-three.jpg'
import imageFour from '../../images/img-five.jpg'
import imageFive from '../../images/img-six.jpg'
import imageSix from '../../images/img-seven.jpg'
import imageSeven from '../../images/img-eight.jpg'
import ContOne from '../../images/con-1.jpg'
import ContTwo from '../../images/con-2.jpg'
import ContThree from '../../images/con-3.jpg'
import ContFour from '../../images/con-4.jpg'
import ContFive from '../../images/con-5.jpg'
import ContSix from '../../images/con-6.jpg'
import ContSeven from '../../images/con-7.jpg'
import LandOne from '../../images/land-1.jpg'
import LandTwo from '../../images/land-2.jpg'
import LandThree from '../../images/land-3.jpg'
import LandFour from '../../images/land-4.jpg'
import LandFive from '../../images/land-5.jpg'
import LandSix from '../../images/land-6.jpg'
import LandSeven from '../../images/land-7.jpg'
import GrassOne from '../../images/grass-1.jpg'
import GrassTwo from '../../images/grass-2.jpg'
import GrassThree from '../../images/grass-3.jpg'
import GrassFour from '../../images/grass-4.jpg'
import GrassFive from '../../images/grass-5.jpg'
import GrassSix from '../../images/grass-6.jpg'
import GrassSeven from '../../images/grass-7.jpg'

export const homeImageData = [

    {
        id:1,
        image: imageOne,
        alt: "landscaping",
        col: 2
    },

    {
        id:2,
        image: imageTwo,
        alt: "landscaping",
        col: 1
    },
    {
        id:3,
        image: imageThree,
        alt: "landscaping",
        col: 1
    },
    {
        id:4,
        image: imageFour,
        alt: "landscaping",
        col: 1
    },
    {
        id:5,
        image: imageFive,
        alt: "landscaping",
        col: 1
    },
    {
        id:6,
        image: imageSix,
        alt: "landscaping",
        col: 1
    },
    {
        id:7,
        image: imageSeven,
        alt: "landscaping",
        col: 2
    },
]
export const ConstructionImageData = [

    {
        id:1,
        image: ContOne,
        alt: "landscaping",
        col: 2
    },

    {
        id:2,
        image: ContTwo,
        alt: "landscaping",
        col: 1
    },
    {
        id:3,
        image: ContThree,
        alt: "landscaping",
        col: 1
    },
    {
        id:4,
        image: ContFour,
        alt: "landscaping",
        col: 1
    },
    {
        id:5,
        image: ContFive,
        alt: "landscaping",
        col: 1
    },
    {
        id:6,
        image: ContSix,
        alt: "landscaping",
        col: 1
    },
    {
        id:7,
        image: ContSeven,
        alt: "landscaping",
        col: 2
    },
]
export const LandscapingImageData = [

    {
        id:1,
        image: LandOne,
        alt: "landscaping",
        col: 2
    },

    {
        id:2,
        image: LandTwo,
        alt: "landscaping",
        col: 1
    },
    {
        id:3,
        image: LandThree,
        alt: "landscaping",
        col: 1
    },
    {
        id:4,
        image: LandFour,
        alt: "landscaping",
        col: 1
    },
    {
        id:5,
        image: LandFive,
        alt: "landscaping",
        col: 1
    },
    {
        id:6,
        image: LandSix,
        alt: "landscaping",
        col: 1
    },
    {
        id:7,
        image: LandSeven,
        alt: "landscaping",
        col: 2
    },
]
export const GrassImageData = [

    {
        id:1,
        image: GrassOne,
        alt: "landscaping",
        col: 2
    },

    {
        id:2,
        image: GrassTwo,
        alt: "landscaping",
        col: 1
    },
    {
        id:3,
        image: GrassThree,
        alt: "landscaping",
        col: 1
    },
    {
        id:4,
        image: GrassFour,
        alt: "landscaping",
        col: 1
    },
    {
        id:5,
        image: GrassFive,
        alt: "landscaping",
        col: 1
    },
    {
        id:6,
        image: GrassSix,
        alt: "landscaping",
        col: 1
    },
    {
        id:7,
        image: GrassSeven,
        alt: "landscaping",
        col: 2
    },
]