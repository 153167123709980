import React, { useEffect } from "react";
import "../components/Offer.css";
import Aos from "aos";
import "aos/dist/aos.css";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
const Offer = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9ry2qtb",
        "template_z2p6ult",
        e.target,
        "user_KGysl3qmSKFIzCF3yRdm3"
      )
      .then(
        (result) => {
          toast.success("Message Sent!", {
            className: "Toast-success",
            draggable: false,
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
          });
          console.log(result.text);
        },
        (error) => {
          toast.error("Something went wrong! please try again", {
            className: "Toast-Error",
            draggable: false,
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
          });
          console.log(error.text);
        }
      );

    e.target.reset();
  }

  return (
    <div id="contact" className="offer-container">
      <div data-aos="fade-left" className="offer-content">
        <h2>Contact us</h2>
        <p>Get in touch now for a free no obligation quote</p>
        <form onSubmit={sendEmail}>
          <div className="name top">
            <div className="text">Name *</div>
            <input type="name" required name="name" />
          </div>
          <div className="email top">
            <div className="text">Email *</div>
            <input type="email" required name="email" />
          </div>
          <div className="msg top ">
            <div className="text">Message *</div>
            <textarea name="message"></textarea>
          </div>
          <div className="btn">
            <button type="submit" value="Send Message">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Offer;
